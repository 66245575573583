body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  
  background-image: url('../statics/background.jpg');
}

.container {
  
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  flex-direction: column;

  height: 100%;
  width: 100%;

  background-color: rgba(1, 1, 1, 0.7)
}

.soon-text {

  color: white;
  font-weight: 100;
  font-size: 50px;

  text-align: center;
}

.footer-text {
  color: white;

  font-weight: 100;
  font-size: 20px;
}